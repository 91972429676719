export const dprInsert = (img = '') => {
  let IS_RETINA = false
  if (typeof window !== 'undefined') {
    IS_RETINA = window.devicePixelRatio > 1 
  }
  const width = IS_RETINA ? '400' : '200'
  if (img.indexOf('oss') > -1) {
    const str = `x-oss-process=image/resize,w_${width}`
    return img.indexOf('?') > -1 ? `${img}&${str}` : `${img}?${str}`
  }
  const reg = /_thumbnail_\d+x\d*/i
  if (reg.test(img)) {
    return img.replace(reg, `_thumbnail_${width}x`)
  }
  return img
}
